export const availableColumnGroups = [
  {
    group: "Account",
    columns: [
      {
        displayValue: "Account",
        value: "account",
      },
      {
        displayValue: "Budget",
        value: "targetSpend",
      },
    ],
    sortableColumns: [],
    filterableColumns: [],
  },
  {
    group: "Category",
    columns: [
      {
        displayValue: "Category",
        value: "category",
      },
    ],
    sortableColumns: [],
    filterableColumns: [],
  },
  {
    group: "Product",
    columns: [
      {
        displayValue: "Product",
        value: "product",
      },
      {
        displayValue: "Tags",
        value: "tags",
      },
      {
        displayValue: "Price",
        value: "priceOrSalePrice",
      },
      {
        displayValue: "Remove Target ROAS Override",
        value: "removeTargetRoasOverride",
      },
      {
        displayValue: "TCM",
        value: "targetContributionMargin",
      },
      {
        displayValue: "CoGS",
        value: "costOfGoodsSold",
      },
      {
        displayValue: "Calculated Target ROAS",
        value: "calculatedTargetRoas",
      },
      {
        displayValue: "Breakeven ROAS",
        value: "breakevenRoas",
      },
    ],
    sortableColumns: [
      {
        displayValue: "Price",
        value: "priceOrSalePrice",
      },
      {
        displayValue: "Breakeven ROAS",
        value: "breakevenRoas",
      },
    ],
    filterableColumns: [
      {
        displayValue: "Price",
        value: "priceOrSalePrice",
      },
      {
        displayValue: "Breakeven ROAS",
        value: "breakevenRoas",
      },
      {
        displayValue: "Tags",
        value: "tags",
      },
    ],
  },
  {
    group: "Performance",
    columns: [
      {
        displayValue: "Ad Spend",
        value: "cost",
      },

      {
        displayValue: "Avg. Daily Spend",
        value: "averageDailySpend",
      },
      {
        displayValue: "Impressions",
        value: "impressions",
      },
      {
        displayValue: "Clicks",
        value: "clicks",
      },
      {
        displayValue: "Target ROAS",
        value: "targetRoasOverride",
      },
    ],
    sortableColumns: [
      {
        displayValue: "Ad Spend",
        value: "cost",
      },
      {
        displayValue: "Ad Spend (Comparison)",
        value: "costComparison",
      },
      {
        displayValue: "Ad Spend Change",
        value: "costChange",
      },
      {
        displayValue: "% Ad Spend Change",
        value: "costChangePercent",
      },
      {
        displayValue: "Avg. Daily Spend",
        value: "averageDailySpend",
      },
      {
        displayValue: "Avg. Daily Spend (Comparison)",
        value: "averageDailySpendComparison",
      },
      {
        displayValue: "Avg. Daily Spend Change",
        value: "averageDailySpendChange",
      },
      {
        displayValue: "% Avg. Daily Spend Change",
        value: "averageDailySpendChangePercent",
      },
      {
        displayValue: "Impressions",
        value: "impressions",
      },
      {
        displayValue: "Impressions (Comparison)",
        value: "impressionsComparison",
      },
      {
        displayValue: "Impressions Change",
        value: "impressionsChange",
      },
      {
        displayValue: "% Impressions Change",
        value: "impressionsChangePercent",
      },
      {
        displayValue: "Clicks",
        value: "clicks",
      },
      {
        displayValue: "Clicks (Comparison)",
        value: "clicksComparison",
      },
      {
        displayValue: "Clicks Change",
        value: "clicksChange",
      },
      {
        displayValue: "% Clicks Change",
        value: "clicksChangePercent",
      },
    ],
    filterableColumns: [
      {
        displayValue: "Ad Spend",
        value: "cost",
      },
      {
        displayValue: "Ad Spend (Comparison)",
        value: "costComparison",
      },
      {
        displayValue: "Ad Spend Change",
        value: "costChange",
      },
      {
        displayValue: "% Ad Spend Change",
        value: "costChangePercent",
      },
      {
        displayValue: "% Ad Spend Change",
        value: "costChangePercent",
      },
      {
        displayValue: "Avg. Daily Spend",
        value: "averageDailySpend",
      },
      {
        displayValue: "Avg. Daily Spend (Comparison)",
        value: "averageDailySpendComparison",
      },
      {
        displayValue: "Avg. Daily Spend Change",
        value: "averageDailySpendChange",
      },
      {
        displayValue: "% Avg. Daily Spend Change",
        value: "averageDailySpendChangePercent",
      },
      {
        displayValue: "Impressions",
        value: "impressions",
      },
      {
        displayValue: "Impressions (Comparison)",
        value: "impressionsComparison",
      },
      {
        displayValue: "Impressions Change",
        value: "impressionsChange",
      },
      {
        displayValue: "% Impressions Change",
        value: "impressionsChangePercent",
      },
      {
        displayValue: "Clicks",
        value: "clicks",
      },
    ],
  },
  {
    group: "Conversions",
    columns: [
      {
        displayValue: "Conversions",
        value: "conversions",
      },
      {
        displayValue: "Conv. Value",
        value: "conversionValue",
      },
      {
        displayValue: "ROAS(Avg.)",
        value: "roas",
      },
    ],
    sortableColumns: [
      {
        displayValue: "Conversions",
        value: "conversions",
      },
      {
        displayValue: "Conversions (Comparison)",
        value: "conversionsComparison",
      },
      {
        displayValue: "Conversions Change",
        value: "conversionsChange",
      },
      {
        displayValue: "% Conversions Change",
        value: "conversionsChangePercent",
      },
      {
        displayValue: "Conv. Value",
        value: "conversionValue",
      },
      {
        displayValue: "Conv. Value (Comparison)",
        value: "conversionValueComparison",
      },
      {
        displayValue: "ConversionValue Change",
        value: "conversionValueChange",
      },
      {
        displayValue: "% ConversionValue Change",
        value: "conversionValueChangePercent",
      },
      {
        displayValue: "ROAS(Avg.)",
        value: "roas",
      },
      {
        displayValue: "ROAS (Comparison)",
        value: "roasComparison",
      },
      {
        displayValue: "% ROAS Change",
        value: "roasChangePercent",
      },
    ],
    filterableColumns: [
      {
        displayValue: "Conversions",
        value: "conversions",
      },
      {
        displayValue: "Conversions (Comparison)",
        value: "conversionsComparison",
      },
      {
        displayValue: "Conversions Change",
        value: "conversionsChange",
      },
      {
        displayValue: "% Conversions Change",
        value: "conversionsChangePercent",
      },
      {
        displayValue: "Conv. Value",
        value: "conversionValue",
      },
      {
        displayValue: "Conv. Value (Comparison)",
        value: "conversionValueComparison",
      },
      {
        displayValue: "ConversionValue Change",
        value: "conversionValueChange",
      },
      {
        displayValue: "% ConversionValue Change",
        value: "conversionValueChangePercent",
      },
      {
        displayValue: "ROAS (Comparison)",
        value: "roasComparison",
      },
      {
        displayValue: "% ROAS Change",
        value: "roasChangePercent",
      },
    ],
  },
  {
    group: "Order Data",
    columns: [
      {
        displayValue: "Revenue",
        value: "revenue",
      },
      {
        displayValue: "Units Sold",
        value: "unitsSold",
      },
    ],
    sortableColumns: [
      {
        displayValue: "Revenue",
        value: "revenue",
      },
      {
        displayValue: "Revenue (Comparison)",
        value: "revenueComparison",
      },
      {
        displayValue: "Revenue Change",
        value: "revenueChange",
      },
      {
        displayValue: "% Revenue Change",
        value: "revenueChangePercent",
      },
      {
        displayValue: "Units Sold",
        value: "unitsSold",
      },
      {
        displayValue: "Units Sold (Comparison)",
        value: "unitsSoldComparison",
      },
      {
        displayValue: "Units Sold Change",
        value: "unitsSoldChange",
      },
      {
        displayValue: "% Units Sold Change",
        value: "unitsSoldChangePercent",
      },
    ],
    filterableColumns: [
      {
        displayValue: "Revenue",
        value: "revenue",
      },
      {
        displayValue: "Revenue (Comparison)",
        value: "revenueComparison",
      },
      {
        displayValue: "Revenue Change",
        value: "revenueChange",
      },
      {
        displayValue: "% Revenue Change",
        value: "revenueChangePercent",
      },
      {
        displayValue: "Units Sold",
        value: "unitsSold",
      },
      {
        displayValue: "Units Sold (Comparison)",
        value: "unitsSoldComparison",
      },
      {
        displayValue: "Units Sold Change",
        value: "unitsSoldChange",
      },
      {
        displayValue: "% Units Sold Change",
        value: "unitsSoldChangePercent",
      },
    ],
  },
  {
    group: "Misc",
    columns: [
      {
        displayValue: "Target ROAS Lock",
        value: "isLocked",
      },
    ],
    sortableColumns: [],
    filterableColumns: [],
  },
  {
    group: "Projected",
    columns: [
      {
        displayValue: "Projected Gross Profit",
        value: "projectedGrossProfit",
      },
      {
        displayValue: "Projected Revenue Per Sale",
        value: "projectedRevenuePerSale",
      },
      {
        displayValue: "Projected Budget Available Per Sale",
        value: "projectedBudgetAvailablePerSale",
      },
    ],
    sortableColumns: [],
    filterableColumns: [],
  },
];

export enum Operator {
  EqualsToOrLessThan = "equalsToOrLessThan",
  EqualsToOrGreaterThan = "equalsToOrGreaterThan",
  Contains = "contains",
  EqualsToWithSymbol = "equalsToWithSymbol",
  LessThan = "lessThan",
  GreaterThan = "greaterThan",
  Tag = "tag",
}

export const filterOperators = [
  {
    displayValue: "Equals To Or Less Than",
    value: Operator.EqualsToOrLessThan,
  },
  {
    displayValue: "Equals To Or Greater Than",
    value: Operator.EqualsToOrGreaterThan,
  },
  {
    displayValue: "Contains",
    value: Operator.Contains,
  },
  {
    displayValue: "Equals To With Symbol",
    value: Operator.EqualsToWithSymbol,
  },
  {
    displayValue: "Less Than",
    value: Operator.LessThan,
  },
  {
    displayValue: "Greater Than",
    value: Operator.GreaterThan,
  },
  {
    displayValue: "Tag",
    value: Operator.Tag,
  },
];
