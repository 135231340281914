import { Box, Button, createSvgIcon, Snackbar } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/RefreshRounded";
import { Alert } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { getProducts, Product } from "../../../api/product";
import { Heading } from "../../../components/Heading";
import { InnerContainer } from "../../../components/InnerContainer";

const columns: GridColDef[] = [
  { field: "sku", headerName: "SKU", width: 150 },
  { field: "title", headerName: "Title", width: 150 },
  { field: "price", headerName: "Price" },
  { field: "salePrice", headerName: "Sale Price" },
  { field: "customerShippingCharge", headerName: "Customer Shipping Charge" },
  { field: "targetContributionMargin", headerName: "Target Contribution Margin" },
  { field: "paymentGatewayCost", headerName: "Payment Gateway Cost" },
  { field: "fulfilmentCost", headerName: "Fulfilment Cost" },
  { field: "costOfGoodsSold", headerName: "Cost Of Goods Sold" },
  { field: "targetRoas", headerName: "Target Roas", width: 150 },
  { field: "targetRoasOverride", headerName: "Target Roas Override", width: 150 },
];

const MAXIMUM_PRODUCTS_FOR_EXPORT = 200000;

export const MPAProductsFieldsMapping = () => {
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [displaySampleProducts, setDisplaySampleProducts] = useState<boolean>(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingProducts(true);
      try {
        const { products, count } = await getProducts({ sample: displaySampleProducts, isDeleted: false });
        setProducts(products || []);
        setProductsCount(count);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingProducts(false);
      }
    };
    fetchData();
  }, [displaySampleProducts]);

  const GridContent = () => {
    if (isLoadingProducts) {
      return <div>Loading...</div>;
    }
    if (products.length === 0) {
      return <div>No Products available - Is there something wrong with the connectors?</div>;
    }

    const ExportIcon = createSvgIcon(
      <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
      "SaveAlt"
    );

    const CustomToolbar = () => {
      const apiRef = useGridApiContext();
      const exportProductsAsCSV = async () => {
        if (productsCount < MAXIMUM_PRODUCTS_FOR_EXPORT) {
          await apiRef.current.exportDataAsCsv();
        } else {
          setShowAlert(true);
        }
        setIsLoadingProducts(false);
      };
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button
            startIcon={<RefreshIcon />}
            onClick={() => {
              setDisplaySampleProducts(!displaySampleProducts);
            }}
          >
            {displaySampleProducts ? "Switch to all products" : "Switch to sample"}
          </Button>
          <Button color="secondary" size="small" startIcon={<ExportIcon />} onClick={() => exportProductsAsCSV()}>
            Export
          </Button>
        </GridToolbarContainer>
      );
    };

    return (
      <DataGridPro
        rows={products}
        columns={columns}
        loading={isLoadingProducts}
        getRowId={(product: Product) => product.sku}
        autoHeight
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>MPA Products State</Heading>
      <InnerContainer>
        <GridContent />
      </InnerContainer>
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={() => setShowAlert(false)}>
        <Alert severity={"error"} variant="filled">
          {`Cannot export ${productsCount} products. Maximum is ${MAXIMUM_PRODUCTS_FOR_EXPORT} Contact Giraffe team`}
        </Alert>
      </Snackbar>
    </Box>
  );
};
