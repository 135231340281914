import { Button, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useEffect, useState } from "react";
import { StyledButton } from "../datasources/Datasources";

export interface ConfirmationDialogProps {
  open: boolean;
  totalRecords: number;
  totalCollections: number;
  disableButtons: boolean;
  onClose(): void;
  onConfirm(): void;
}

export function ConfirmationDialog({
  open,
  totalRecords,
  totalCollections,
  disableButtons,
  onClose,
  onConfirm,
}: ConfirmationDialogProps) {
  const [confirmationText, setConfirmationText] = useState("");

  useEffect(() => {
    setConfirmationText("");
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle title="Delete Data" />
      <DialogContent>
        <b> Tenant: {window.USER_INFO.tenantId}</b>
        <Typography variant="body2">
          Please enter the word "delete" below to confirm deletion of {totalRecords} records from {totalCollections}{" "}
          collections.
          <br />
          CAUTION: THIS ACTION CANNOT BE REVERSED!
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          fullWidth
          value={confirmationText}
          onChange={(event) => {
            setConfirmationText(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={disableButtons}>
          Cancel
        </Button>
        <StyledButton
          color="primary"
          variant="outlined"
          disabled={confirmationText !== "delete" || disableButtons}
          onClick={onConfirm}
        >
          Delete Data
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
